import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { BackButton } from "../../../components/BackButton";
import { GridGrow } from "../../../components/GridGrow";
import { NumberTextField } from "../../../components/NumberTextField";
import { PercentTextField } from "../../../components/PercentTextField";
import { PriceTextField } from "../../../components/PriceTextField";
import { EarlyPaymentDiscount, OverdueFee, PaymentTerms } from "../../../entities/billing/PaymentTerms";
import { useAlert } from "../../../hooks/useAlert";
import { PaymentTermsService } from "../../../services/billing/PaymentTermsService";
import { usePaymentTermsPage } from "./PaymentTermsPage";

const defaultOverdueFee: OverdueFee = { percentFee: 0.01, fixedFee: null, minimumFeeAmount: null };
const defaultEarlyPaymentDiscount: EarlyPaymentDiscount = { paidWithinDays: 5, percentDiscount: 0.01, fixedDiscount: null, maximumDiscountAmount: null };

export function PaymentTermsDetailView(props: { paymentTerms: PaymentTerms }) {
	const { paymentTerms } = props;

	const context = usePaymentTermsPage();
	const alert = useAlert();

	const [disabled, setDisabled] = React.useState(false);

	const [name, setName] = React.useState(paymentTerms.name);
	const [description, setDescription] = React.useState(paymentTerms.description);
	const [daysToPay, setDaysToPay] = React.useState(paymentTerms.dueDays);
	const [graceDays, setGraceDays] = React.useState(paymentTerms.graceDays);
	const [overdueFee, setOverdueFee] = React.useState(paymentTerms.overdueFee);
	const [earlyPaymentDiscount, setEarlyPaymentDiscount] = React.useState(paymentTerms.earlyPaymentDiscount);

	const setToDefault = async () => {
		setDisabled(true);
		const result = await PaymentTermsService.update({ paymentTermsId: paymentTerms.id, isDefault: true });
		setDisabled(false);
		if (result.success) {
			alert.success(`${paymentTerms.name} is now the default payment terms`);
			context.setDefaultPaymentTerms(result.data);
		} else if (result.validation) {
			alert.validation(result);
		} else {
			alert.serverError(result);
		}
	};

	const onSave = async () => {
        setDisabled(true);
        const result = await PaymentTermsService.update({
            paymentTermsId: paymentTerms.id,
            name,
            description,
            dueDays: daysToPay,
            graceDays,
            overdueFee,
            earlyPaymentDiscount,
        });
        setDisabled(false);
        if (result.success) {
            alert.success(`${paymentTerms.name} has been updated`);
            context.updatePaymentTerms(result.data);
        } else if (result.validation) {
            alert.validation(result);
        } else {
            alert.serverError(result);
        }
    };

	return (
		<Grid container spacing={2}>
			<Grid item xl={5} lg={7} md={9} sm={10} xs={12}>
				<Card>
					<Grid container alignItems="center">
						<Grid item>
							<BackButton />
						</Grid>
						<Grid item>
							<CardHeader title="Update payment terms" />
						</Grid>
						<GridGrow />

						<Grid item>
							<Box mr={1}>
								{paymentTerms.isDefault ? (
									<Typography variant="body2" color="textSecondary">
										Default Terms
									</Typography>
								) : (
									<Button variant="outlined" color="primary" onClick={setToDefault} disabled={disabled} style={{ marginRight: 5 }}>
										Set as Default
									</Button>
								)}
							</Box>
						</Grid>
					</Grid>
					<CardContent>
						<Grid spacing={2} container>
							<Grid item sm={6} xs={12}>
								<TextField label="Name" variant="outlined" fullWidth value={name} onChange={(e) => setName(e.target.value)} disabled={disabled} />
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									label="Description"
									variant="outlined"
									fullWidth
									value={description}
									onChange={(e) => setDescription(e.target.value)}
									disabled={disabled}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<NumberTextField label="Days to Pay" variant="outlined" fullWidth value={daysToPay} onNumberChange={n => setDaysToPay(n ?? 0)} disabled={disabled} />
							</Grid>
							<Grid item sm={6} xs={12}>
								<NumberTextField label="Grace Days" variant="outlined" fullWidth value={graceDays} onNumberChange={n => setGraceDays(n ?? 0)} disabled={disabled} />
							</Grid>

							<Grid item xs={12}>
								{!overdueFee && (
									<Button variant="outlined" onClick={() => setOverdueFee(defaultOverdueFee)} disabled={disabled} style={{ marginRight: 10 }}>
										Add Overdue Fee
									</Button>
								)}
								{!earlyPaymentDiscount && (
									<Button variant="outlined" onClick={() => setEarlyPaymentDiscount(defaultEarlyPaymentDiscount)} disabled={disabled}>
										Add Early Payment Discount
									</Button>
								)}
							</Grid>
						</Grid>
					</CardContent>
					{overdueFee && (
						<>
							<Divider />
							<CardHeader title="Overdue Fee" subheader="Overdue fee will be applied to invoices that are overdue at the end of the month" />
							<CardContent>
								<Grid container spacing={2}>
									<Grid item sm={6} xs={12}>
										<PercentTextField
											label="Percent Fee"
											variant="outlined"
											fullWidth
											value={overdueFee.percentFee ?? 0}
											onPercentChange={(percentFee) => setOverdueFee({ ...overdueFee, percentFee })}
											disabled={disabled}
											helperText="Charge a percentage of the invoice amount"
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<PriceTextField
											label="Fixed Fee"
											variant="outlined"
											fullWidth
											value={overdueFee.fixedFee ?? 0}
											onPriceChanged={(fixedFee) => setOverdueFee({ ...overdueFee, fixedFee })}
											disabled={disabled}
											helperText="Charge a fixed late fee dollar amount"
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<PriceTextField
											label="Minimum Fee"
											variant="outlined"
											fullWidth
											value={overdueFee.minimumFeeAmount ?? 0}
											onPriceChanged={(minimumFeeAmount) => setOverdueFee({ ...overdueFee, minimumFeeAmount })}
											disabled={disabled}
											helperText="Minimum fee to charge if above fee is less than this amount"
										/>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Button variant="outlined" color="secondary" onClick={() => setOverdueFee(null)} disabled={disabled}>
											Remove Fee
										</Button>
									</Grid>
								</Grid>
							</CardContent>
						</>
					)}
                    {earlyPaymentDiscount && (
                        <>
                            <Divider />
                            <CardHeader title="Early Payment Discount" subheader="Early payment discount will be applied to invoices that are paid before the due date" />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <PercentTextField
                                            label="Percent Discount"
                                            variant="outlined"
                                            fullWidth
                                            value={earlyPaymentDiscount.percentDiscount ?? 0}
                                            onPercentChange={(percentDiscount) => setEarlyPaymentDiscount({ ...earlyPaymentDiscount, percentDiscount })}
                                            disabled={disabled}
                                            helperText="Discount a percentage of the invoice amount"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <PriceTextField
                                            label="Fixed Discount"
                                            variant="outlined"
                                            fullWidth
                                            value={earlyPaymentDiscount.fixedDiscount ?? 0}
                                            onPriceChanged={(fixedDiscount) => setEarlyPaymentDiscount({ ...earlyPaymentDiscount, fixedDiscount })}
                                            disabled={disabled}
                                            helperText="Discount a fixed dollar amount"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <PriceTextField
                                            label="Maximum Discount"
                                            variant="outlined"
                                            fullWidth
                                            value={earlyPaymentDiscount.maximumDiscountAmount ?? 0}
                                            onPriceChanged={(maximumDiscountAmount) => setEarlyPaymentDiscount({ ...earlyPaymentDiscount, maximumDiscountAmount })}
                                            disabled={disabled}
                                            helperText="Maximum discount to apply if above discount is greater than this amount"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <NumberTextField
                                            label="Paid Within Days"
                                            variant="outlined"
                                            fullWidth
                                            value={earlyPaymentDiscount.paidWithinDays}
                                            onNumberChange={(paidWithinDays) => setEarlyPaymentDiscount({ ...earlyPaymentDiscount, paidWithinDays: paidWithinDays ?? 0 })}
                                            disabled={disabled}
                                            helperText="Number of days before the due date that the invoice must be paid to receive the discount"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <Button variant="outlined" color="secondary" onClick={() => setEarlyPaymentDiscount(null)} disabled={disabled}>
                                            Remove Discount
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </>
                    )}
					<Button variant="contained" color="primary" onClick={onSave} disabled={disabled} fullWidth>
						Save
					</Button>
				</Card>
			</Grid>
		</Grid>
	);
}
