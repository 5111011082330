import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { NumberTextField } from "../../../components/NumberTextField";
import { useAlert } from "../../../hooks/useAlert";
import { routes } from "../../../routes";
import { PaymentTermsService } from "../../../services/billing/PaymentTermsService";
import { usePaymentTermsPage } from "./PaymentTermsPage";

export function CreatePaymentTermsDialog(props: { open: boolean; onClose: () => void }) {
    const alert = useAlert();
    const history = useHistory();
    const context = usePaymentTermsPage();

	const [name, setName] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [daysToPay, setDaysToPay] = React.useState(30);
	const [graceDays, setGraceDays] = React.useState(0);

	const [disabled, setDisabled] = React.useState(false);

	const onCreate = async () => {
		setDisabled(true);
        const result = await PaymentTermsService.create({
            name,
            description,
            dueDays: daysToPay,
            graceDays,
            isDefault: false,
            earlyPaymentDiscount: null,
            overdueFee: null,
        });
        setDisabled(false);
        if(result.success) {
            alert.success("Payment Terms Created");
            context.addPaymentTerms(result.data);
            history.push(routes.business.resolve.paymentTermsDetailPage(result.data.id));
        } else if(result.validation){
            alert.validation(result);
        } else{
            alert.serverError(result);
        }
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth>
			<DialogTitle>Create Payment Terms</DialogTitle>
			<DialogContent>
				<Grid spacing={2} container>
					<Grid item sm={6} xs={12}>
						<TextField label="Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} disabled={disabled} />
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField label="Description" fullWidth value={description} onChange={(e) => setDescription(e.target.value)} disabled={disabled} />
					</Grid>
					<Grid item sm={6} xs={12}>
						<NumberTextField label="Days to Pay" fullWidth value={daysToPay} onNumberChange={n => setDaysToPay(n ?? 0)} disabled={disabled} />
					</Grid>
					<Grid item sm={6} xs={12}>
						<NumberTextField label="Grace Days" fullWidth value={graceDays} onNumberChange={n => setGraceDays(n ?? 0)} disabled={disabled} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} variant="outlined" color="secondary" disabled={disabled}>
					Cancel
				</Button>
				<Button onClick={onCreate} variant="contained" color="primary" disabled={disabled}>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
