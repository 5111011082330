import { EarlyPaymentDiscount, OverdueFee, PaymentTerms } from "../../entities/billing/PaymentTerms";
import { Modify } from "../../utility/modifyType";
import { OptionalValue, toOptional } from "../../utility/OptionalValue";
import { apiServer } from "../server/Setting";
import { WebClient } from "../server/WebClient";

interface CreatePaymentTermsRequest{
    name: string;
    description: string;
    dueDays: number;
    graceDays: number;
    isDefault: boolean;
    overdueFee: OverdueFee | null;
    earlyPaymentDiscount: EarlyPaymentDiscount | null;
}

interface UpdatePaymentTermsRequest{
    paymentTermsId: string;
    name?: string;
    description?: string;
    dueDays?: number;
    graceDays?: number;
    isDefault?: boolean;
    overdueFee?: OverdueFee | null;
    earlyPaymentDiscount?: EarlyPaymentDiscount | null;
}

type UpdatePaymentTermsServerRequest = Modify<UpdatePaymentTermsRequest, {
    overdueFee?: OptionalValue<OverdueFee | null>;
    earlyPaymentDiscount?: OptionalValue<EarlyPaymentDiscount | null>;
}>;

function serializeUpdateRequest(request: UpdatePaymentTermsRequest): UpdatePaymentTermsServerRequest{
    return {
        ...request,
        overdueFee: toOptional(request.overdueFee),
        earlyPaymentDiscount: toOptional(request.earlyPaymentDiscount),
    }
}

export const PaymentTermsService = {
    getAll: () => WebClient.Get<PaymentTerms[]>(`${apiServer}/api/payment-terms`),
    get: (id: string) => WebClient.Get<PaymentTerms>(`${apiServer}/api/payment-terms/${id}`),
    getCustomerPaymentTerms: (customerId: string) => WebClient.Get<PaymentTerms>(`${apiServer}/api/payment-terms/customer/${customerId}`),
    create: (request: CreatePaymentTermsRequest) => WebClient.Post.Validated<PaymentTerms>(`${apiServer}/api/payment-terms`, request),
    update: (request: UpdatePaymentTermsRequest) => WebClient.Put.Validated<PaymentTerms>(`${apiServer}/api/payment-terms`, serializeUpdateRequest(request)),
    assignToCustomer: (request: {paymentTermsId: string, customerId: string}) => WebClient.Put.Validated<PaymentTerms>(`${apiServer}/api/payment-terms/customer`, request),
}